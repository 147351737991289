.content {
  table {
    tr {
      td {
        padding-left: 0;
        border: none;
      }
    }
  }
}

.button {
  min-width: 140px;
  margin-left: 1rem;
  transition: background-color 0.175s ease-in;

  &.is-success.fadeColor {
    transition: background-color 0.175s ease-out;
  }
}